/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains divider CSS.
*/

/*************************************
          divider
 **************************************/
.divider { border-bottom: 1px solid rgba(0, 0, 0, 0.1);  }
.divider.dotted { border-bottom: 2px dotted rgba(0, 0, 0, 0.1);  }
.divider.dashed { border-bottom: 2px dashed rgba(0, 0, 0, 0.1);  }
.divider.double { border-bottom: 5px double rgba(0, 0, 0, 0.1);  }
.divider.outset { border-bottom: 3px outset rgba(0, 0, 0, 0.1);  }
 
/*icon*/
.divider.icon { border: 0; color: #ccc; width: 100%; overflow: hidden; text-align: center;  } 
.divider.icon:after, .divider.icon:before, .divider.icon:after, .divider.icon:before { border-bottom: 1px solid rgba(0, 0, 0, 0.2); content: ""; display: inline-block; height: 10px;
    margin: 0 -4px 0 -100%; vertical-align: top; width: 50%; }
.divider.icon::after, .divider.icon::after { margin: 0 -100% 0 0; }
.divider.icon i, .divider.icon span, .divider.icon i, .divider.icon span { margin: 0 20px 0 24px; }
 
/*icon left*/
.divider.icon.left i, .divider.icon.left span { float: left; margin: 0 40px 0 0; }
.divider.icon.right i, .divider.icon.right span { float: right; margin: 0 0 0 40px; }

/*medium*/
.divider.medium { width: 40%; margin: 0 auto; }

/*small*/
.divider.small { width: 20%; margin: 0 auto; }
.divider.light { border-color: rgba(255, 255, 255, 0.1); }