/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Pie chart CSS.
*/


/***************************
      pie-chart
***************************/
.round-chart { display: inline-block; position: relative; text-align: center; margin-bottom: 20px; }
.round-chart span, .round-chart i { display: inline-block; font-size: 28px; z-index: 2; font-weight: 600; position: absolute; }
.round-chart.white span, .round-chart.white i { color: #ffffff; }
