/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Clients logo CSS.
*/

/*************************
        Clients
*************************/
.clients-list { overflow: hidden; }
.clients-list ul li { float: left; position: relative; width: 20%; padding: 20px; }
.clients-list.clients-border ul li:before { height: 100%; top: 0; left: -1px; border-left: 1px solid #eee; }
.clients-list.clients-border ul li:after, .clients-list.clients-border ul li:before { content: ''; position: absolute; }
.clients-list.clients-border ul li:after { width: 100%; height: 0; top: auto; left: 0; bottom: -1px; border-bottom: 1px solid #eee; }
 
/*column-4*/
 .clients-list.column-4 ul li { float: left; position: relative; width: 25%; padding: 10px; }

/*column-3*/
.clients-list.column-3 ul li { float: left; position: relative; width: 33.333%; padding: 10px; }

/*column-2*/
.clients-list.column-2 ul li { float: left; position: relative; width: 50%; padding: 10px; }

.clients-list.grayscale img { filter: gray; -webkit-filter: grayscale(100%); -o-filter: grayscale(100%); -ms-filter: grayscale(100%); -moz-filter: grayscale(100%); filter: grayscale(100%); transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;  -ms-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; }
.clients-list.grayscale img:hover { -webkit-filter: grayscale(0%); -o-filter: grayscale(0%); -ms-filter: grayscale(0%); -moz-filter: grayscale(0%); filter: grayscale(0%); }
