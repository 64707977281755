/*

Template: Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE:  This file includes all shortcode css. If you don't want to use any shortcode from below. You can simply remove that line from this file.

*/
 
/*shortcode*/
@import url("accordion.css");
@import url("action-box.css");
@import url("blockquote.css");
@import url("bootstrap-typography.css");
@import url("button.css");
@import url("clients.css");
@import url("contact-form.css");
@import url("countdown.css");
@import url("counter.css");
@import url("divider.css");
@import url("dropcap.css");
@import url("feature-text.css");
@import url("list-style.css");
@import url("nice-select.css");
@import url("owl-carousel.css");
@import url("page-title.css");
@import url("pie-chart.css");
@import url("pricing.css");
@import url("progress-bar.css");
@import url("section-title.css");
@import url("shortcodes.css");
@import url("social-icons.css");
@import url("tabs.css");
@import url("team.css");
@import url("testimonial.css");
@import url("side-panel.css");
@import url("onload-modal.css");
