/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Dropcap & Highlight CSS.
*/

/*************************************
           Dropcap & Highlight
 **************************************/
 .dropcap { width: 40px; height: 40px; line-height: 40px; float: left; border-radius: 50%; background: #006341; text-align: center; color: #ffffff; margin-right: 10px; }
 .dropcap.dropcap-border { background: transparent; border:2px solid #006341; color: #006341; }
 .dropcap.gray { background: #f6f7f8; color: #006341; }
 .dropcap.large { width: 70px; height: 70px; line-height: 70px; font-size: 30px; }
 .dropcap.square {  border-radius: 3px; }