/*

Template: Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE:  This file includes all plugins. If you don't want to use any plugins from below. You can simply remove that line from this file.

*/
 
/*plugins*/
@import url("/assets/vendor/css/bootstrap.min.css");
@import url("/assets/vendor/css/mega_menu.css");
@import url("/assets/vendor/css/animate.css");
@import url("/assets/vendor/css/font-awesome.min.css");
@import url("/assets/vendor/css/bootstrap-datetimepicker.min.css");
@import url("/assets/vendor/css/dataTables.bootstrap4.min.css");
@import url("/assets/vendor/css/magnific-popup.css");
@import url("/assets/vendor/css/mediaelementplayer.css");
@import url("/assets/vendor/css/owl.carousel.min.css");
@import url("/assets/vendor/css/slick-theme.css");
@import url("/assets/vendor/css/themify-icons.css");
@import url("/assets/vendor/css/fullcalendar.css");
