/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Bootstrap Typography CSS.
*/

.pager { margin: 0; }
.affix { left: 0; right: 0; }
.btn-primary { background-color: #006341; border-color: #006341; }
.btn-primary:hover { background-color: #323232; border-color: #323232; color: #ffffff; }
.btn-primary.focus, .btn-primary:focus { background-color: #323232; border-color: #323232; color: #ffffff; }
.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover { background-color: #006341; }
select.input-sm { height: 50px; }
.nice-select:active, .nice-select.open, .nice-select:focus { border-color:#006341 !important; }
.list-group-item.active>.badge, .nav-pills>.active>a>.badge, .btn-primary .badge { color: #006341;  }
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover { border-color: #006341; }
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover { background-color: #006341; border-color: #006341 }
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover { background: #006341; }
.input-group-prepend:last-child { border: 0; }
.input-group-prepend { border-left:1px solid #e0e0e0 !important; padding: 16px 26px 16px 22px; background: #f6f7f8; border-radius: 3px; }
.datetimepicker .form-control:focus { box-shadow: none; background-color: #f6f7f8; }
.modal1 .mfp-close { display: none; }
.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand { margin-left: 0; }
.dropdown-item.active, .dropdown-item:active { background-color: #006341;  }
.bootstrap-datetimepicker-widget table td span.active { background-color: #006341;  }

/*************************
    Process step
*************************/
.stepwizard-step p { margin-top: 10px; }
.stepwizard-row { display: table-row;}
.stepwizard { display: table; width: 100%; position: relative; }
.stepwizard-step button[disabled] { opacity: 1 !important; filter: alpha(opacity=100) !important; }
.stepwizard-row:before { top: 24px; bottom: 0; position: absolute; content: " "; width: 100%; height: 1px; background-color: #ccc; }
.stepwizard-step { display: table-cell; text-align: center; position: relative; }
.btn-circle { width: 50px; height: 50px; text-align: center; padding: 6px 0; font-size: 12px; line-height: 1.428571429; border-radius: 3px; }

/*************************
    responsive-utilities
*************************/
.responsive-utilities .is-visible { color: #468847; background-color: #dff0d8!important; }
.responsive-utilities .is-hidden { color: #ccc; background-color: #f9f9f9!important; }
.hidden-on .col-6 .visible, .visible-on .col-6 .visible { color: #468847; background-color: #dff0d8; border: 1px solid #d6e9c6; }

/*************************************
          animations
 **************************************/
.bg { padding: 30px; border-radius: 3px; margin-bottom: 30px; }
.bg-01 { background: #fe80c0; }
.bg-02 { background: #b24a7d; }
.bg-03 { background: #76bcff; }
.bg-04 { background: #8d708e; }
.bg-05 { background: #807fff; }
.bg-06 { background: #e74c3c; }
.bg-07 { background: #16a085; }
.bg-08 { background: #f1c40f; }
.bg-09 { background: #27ae60; }
.bg-10 { background: #1abc9c; }
.bg-11 { background: #9b59b6; }
.bg-12 { background: #3498db; }
.bg-13 { background: #ff6d3a; }
.bg-14 { background: #2ecc71; }
.bg-15 { background: #32cdc7; }
.bg-16 { background: #009a9a; }

/*************************
	   columns
*************************/
.columns .gray-bg { padding: 15px; border-radius: 3px;}
.columns .gray-bg h5 { margin-bottom: 0px; }

/*************************
  data table
*************************/
.table { margin-bottom: 0; }
.table td, .table th { vertical-align: middle; }
.table-responsive>.table-bordered { border:1px solid #dee2e6; }
.table-bordered tbody tr td, .table-bordered thead tr th { border-color: #eee;  font-size: 14px; padding: 12px 20px; }
.table-1 thead { background: #006341; color: #fff; text-align: center; }
.table-1 thead tr th { border: medium none;   font-weight: normal;  text-align: center;}
.table-1 tbody tr td { color: #7e8890; font-size: 14px; padding: 12px 0; text-align: center; }
.table-2.table thead { background: transparent; color: #006341; font-weight: 600; text-align: center; }
.table-2.table thead tr th { border: 1px solid #006341 !important; text-align: center; }
.table-3.table thead { background: #dfdfdf; color: #323232; font-weight: 600; text-align: center; }
.table-3.table thead tr th { border:none; text-align: center; }

/*************************
	Typography
*************************/
.typography del, s, u { font-size: 14px; }
.typography .mark, mark { background: #006341; color: #fff;  }
del, mark { background: #006341; color: #ffffff; padding: 0 5px; margin-right: 3px; }
 

/*************************
   alerts and callouts
*************************/
.alerts-and-callouts  { font-size: 14px;  }
.alerts-and-callouts .bs-callout { padding: 20px; margin: 20px 0; border: 1px solid #eee; border-left-width: 5px; border-radius: 3px; }
.alerts-and-callouts .bs-callout h4 {  margin-top: 0; margin-bottom: 5px; }
.alerts-and-callouts .bs-callout p:last-child { margin-bottom: 0; }
.alerts-and-callouts .bs-callout code { border-radius: 3px; }
.alerts-and-callouts .bs-callout+.bs-callout { margin-top: -5px; }
.alerts-and-callouts .bs-callout-default { border-left-color: #777; }
.alerts-and-callouts .bs-callout-default h4 { color: #777; }
.alerts-and-callouts .bs-callout-primary { border-left-color: #428bca; }
.alerts-and-callouts .bs-callout-primary h4 { color: #428bca; }
.alerts-and-callouts .bs-callout-success { border-left-color: #5cb85c; }
.alerts-and-callouts .bs-callout-success h4 { color: #5cb85c; }
.alerts-and-callouts .bs-callout-danger { border-left-color: #d9534f; }
.alerts-and-callouts .bs-callout-danger h4 { color: #d9534f; }
.alerts-and-callouts .bs-callout-warning { border-left-color: #f0ad4e; }
.alerts-and-callouts .bs-callout-warning h4 { color: #f0ad4e; }
.alerts-and-callouts .bs-callout-info { border-left-color: #5bc0de; }
.alerts-and-callouts .bs-callout-info h4 { color: #5bc0de; }

@media screen and (max-width: 767px) {
.animated  { margin-top: 30px; }
.data-table .table tbody tr td { padding: 12px 10px; }
.tab-2 #tabs .tabs { width: 100%; }
.nav-tabs li a { font-size: 14px; }
.tab-1 li { margin-right: 2px; }
}

 /*************************
        isotope 
*************************/ 
.isotope-item{z-index:2}.isotope-hidden.isotope-item{pointer-events:none;z-index:1}.isotope,.isotope .isotope-item{-webkit-transition-duration:.8s;-moz-transition-duration:.8s;-ms-transition-duration:.8s;-o-transition-duration:.8s;transition-duration:.8s}.isotope{-webkit-transition-property:height,width;-moz-transition-property:height,width;-ms-transition-property:height,width;-o-transition-property:height,width;transition-property:height,width}.isotope .isotope-item{-webkit-transition-property:-webkit-transform,opacity;-moz-transition-property:-moz-transform,opacity;-ms-transition-property:-ms-transform,opacity;-o-transition-property:-o-transform,opacity;transition-property:transform,opacity}.isotope .isotope-item.no-transition,.isotope.no-transition,.isotope.no-transition .isotope-item{-webkit-transition-duration:0s;-moz-transition-duration:0s;-ms-transition-duration:0s;-o-transition-duration:0s;transition-duration:0s}

 /*************************
        lists panels 
*************************/ 
.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover { background-color: #006341; border-color: #006341;  }
.panel-primary { border-color: #006341;  }
.panel-primary>.panel-heading  {  background-color: #006341; border-color: #006341; }
.badge-primary, .nav-pills .nav-link.active, .nav-pills .show>.nav-link, .bg-primary { background: #006341 !important; }
.border-primary { border-color: #006341 !important; }

 /*************************
        lists panels 
*************************/ 
.modal { z-index: 99999; }
.modal-backdrop { z-index: 9999; }
.modal-dialog { margin: 100px auto; }
.modal-header, .modal-footer { border: 0; }
.modal-header, .modal-body { padding: 30px 30px 0 30px; }
.modal-footer { padding: 30px 30px 30px 30px;  text-align: left; }
.modal-header .close { position: relative; z-index: 99; }
 
 /*************************
       extra class
*************************/ 
.image-content { float: left; width: 50%; margin: 0 20px 20px 0 ; }
select.form-control-sm:not([size]):not([multiple]) { height: calc(1.8125rem + 22px); }
.dataTables_length .form-control { padding: 15px 8px 15px 20px; }
div.dataTables_wrapper div.dataTables_paginate, div.dataTables_wrapper div.dataTables_info { margin-top: 15px; }
