/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Accordion CSS.
*/

/***************************
      Accordion
***************************/
.accordion .acd-group .acd-heading:before { font-family: fontawesome; }
.accordion .acd-group .acd-heading:before { cursor: pointer; position: absolute; top: 6px; right: 20px; display: block; padding: 3px 6px 2px; content: "\f105"; font-size: 22px; line-height: 38px; }
.accordion .acd-group > .acd-heading > span { margin-right: 15px; }
.accordion .acd-heading { font-weight: 500; position: relative; padding: 20px 0; color: #353535; line-height: normal; cursor: pointer; background-color: transparent; margin-bottom: 0px; display: block; font-family: 'Montserrat', sans-serif; text-transform: uppercase; border-radius: 3px;  }
.acd-des { padding: 0 20px 20px 0; }
.accordion .acd-group.acd-active .acd-heading, .accordion .acd-group .acd-heading:hover { color: #006341; }
.accordion .acd-group.acd-active .acd-heading:before { content: "\f107"; }
.accordion .acd-group.acd-active .acd-heading span { color: #fff; }

/*plus-icon*/
.accordion.plus-icon .acd-group.acd-active .acd-heading:before { content: "\f068"; font-size: 14px; }
.accordion.plus-icon .acd-group .acd-heading:before { content: "\f067"; font-size: 14px; }

/*plus-icon round*/
.accordion.plus-icon.round .acd-group.acd-active .acd-heading:before { content: "\f056"; font-size: 14px; }
.accordion.plus-icon.round .acd-group .acd-heading:before { content: "\f055"; font-size: 14px; }

/*gray*/
.accordion.gray .acd-heading { background-color: #f7f7f7; }
.accordion.gray .acd-heading  { margin-bottom: 20px; padding: 20px 30px; }
.accordion.gray .acd-des { padding: 0 30px 20px; }
.accordion.gray .acd-group.acd-active .acd-heading, .accordion.gray .acd-group .acd-heading:hover { color: #fff; background: #006341; }

/*shadow*/
.accordion.shadow .acd-heading { box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); background: #ffffff; }
.accordion.shadow .acd-heading  { margin-bottom: 20px; padding: 20px 30px; }
.accordion.shadow .acd-des  { padding: 0 30px 20px; }
.accordion.shadow .acd-group.acd-active .acd-heading, .accordion.shadow .acd-group .acd-heading:hover { color: #fff; background: #006341; }


/*border*/
.accordion.accordion-border .acd-heading { background: transparent; border:1px solid #eeeeee; box-shadow: none; }
.accordion.accordion-border .acd-heading  { margin-bottom: 20px; padding: 20px 30px; }
.accordion.accordion-border .acd-des  { padding: 0 30px 20px; }
.accordion.accordion-border .acd-group.acd-active .acd-heading, .accordion.accordion-border .acd-group .acd-heading:hover { color: #fff; background: #006341; }

/* Accordion Simple */
.accordion.animated .acd-group .acd-heading { box-shadow: none; border:none; font-size: 18px; margin-bottom: 0; color:#363636; text-transform: inherit; font-weight: 700; padding: 20px 0px 0; }
.accordion.animated .acd-group.acd-active .acd-heading { font-size: 28px; color: #006341; margin-bottom: 20px; background: none !important;}
.accordion.animated .acd-group .acd-heading:hover{background: none !important; color: #006341;}
.accordion.animated .acd-group .acd-heading::before { display: none; }
.accordion.animated .acd-group .acd-des { padding: 0 30px 20px; }

.accordion.animated.dark-bg .acd-group.acd-active .acd-heading { color: #ffffff; }
.accordion.animated.dark-bg .acd-group .acd-heading:hover { color: #ffffff; } 

/* No border radius*/
.accordion.no-radius .acd-heading{ border-radius: 0; }